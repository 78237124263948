import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExamplePdfViewerComponent } from './example-pdf-viewer/example-pdf-viewer.component';

import { registerPlugin } from '@capacitor/core';

const Echo = registerPlugin<EchoPlugin>('Echo');
export interface EchoPlugin {
  echo(options: { value: string }): Promise<{ value: string }>;
}

export default Echo;

@NgModule({
  declarations: [AppComponent, ExamplePdfViewerComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
